.church-introduction {
  display: flex;
  align-items: flex-start; /* Align items at the top */
  justify-content: space-between; /* Ensure spacing between the image and text */
  padding: 60px 20px;
  border-radius: 20px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  flex-direction: row; /* Ensure horizontal layout for large screens */
}

.church-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%; /* 40% width for the image */
  max-width: 600px; /* Limit maximum width */
  margin-right: 20px; /* Add space between image and text */
}

.church-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  border: 3px solid #ffffff;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.church-image:hover {
  transform: scale(1.05);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.5);
}

.church-image:active {
  transform: scale(0.98);
}

.church-text {
  width: 100%;
  max-width: 800px; /* Limit width of text content */
  padding: 20px;
  text-align: center; /* Center-align the text */
}


.church-text h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 25px;
  color: #2c3e50;
}

.church-text p {
  font-size: 20px;
  line-height: 1.6;
  margin: 0;
  white-space: pre-wrap;
  color: #555;
}

/* Responsive adjustments for mobile screens */
@media (max-width: 768px) {
  .church-introduction {
    flex-direction: column; /* Stack the image and text vertically */
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .church-image-container {
    width: 100%; /* Full width for mobile */
    margin-bottom: 75px !important; /* Reduce space between image and text */
  }

  .church-text {
    width: 100%; /* Full width for mobile */
    text-align: center; /* Center text for mobile */
    padding: 0 15px; /* Add padding for better readability */
    margin: 0 auto; /* Center the text block */
  }

  .church-text h1 {
    font-size: 26px !important; /* Increase heading font size for mobile */
    margin-bottom: 15px; /* Add space below the heading */
  }

  .church-text p {
    font-size: 27px !important;
    line-height: 1.8;
    text-align: center; /* Ensure centered text */
    max-width: 600px; /* Increase the width for better word distribution */
    margin: 0 auto; /* Center the text block */
    white-space: pre-wrap; /* Ensure the text wraps properly */
    word-wrap: break-word; /* Ensure long words wrap */
    margin-bottom: 70px;
  }
}



/* Extra small screen adjustments */
@media (max-width: 480px) {
  .church-text h1 {
    font-size: 20px; /* Further reduce font size for very small screens */
  }

  .church-text p {
    font-size: 14px; /* Further reduce text size for readability */
    line-height: 1.4; /* Adjust line height for small screens */
    margin-bottom: 70px;
  }
}