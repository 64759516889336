/* NewsDetail.css */

.announcement-detail-page {
    text-align: center;
    background-color: #f4f4f9; /* Add a background color */
    padding-bottom: 40px;
  }
  
  .header-image-container {
    position: relative;
    width: 100%;
    height: 300px; /* Adjusted height */
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .header-text {
    position: absolute;
    bottom: 40px; /* Move it higher */
    left: 50px; /* Move it more to the right */
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Add text shadow for clarity */
  }
  
  .header-text h1 {
    font-size: 36px;
    margin: 0;
  }
  
  .header-text p {
    font-size: 18px;
    margin: 5px 0 0;
  }
  
  .announcement-detail {
    padding: 20px 10%; /* Less padding and centered */
    text-align: left;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding shadow to separate the content */
    border-radius: 10px;
    margin: 0 auto;
  }
  
  
  .announcement-detail h1 {
    font-size: 28px;
    margin-bottom: 10px;
    text-align: center; /* Center the title */
  }
  
  .announcement-detail p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  .back-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  