.news-page {
    padding: 0px;
}

.header-image-container {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    margin-bottom: 40px;
}

.header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-text {
    position: absolute;
    bottom: 130px; /* Moves the text higher */
    left: 150px; /* Moves the text more to the right */
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Adds shadow for better readability */
}

.header-text h1 {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    color: white;
}

.header-text p {
    font-size: 18px;
    margin: 0;
}

.news-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 60px;
}

.news-table th,
.news-table td {
    border: 1px solid #eaeaea;
    padding: 12px 15px;
    text-align: left;
}

.news-table th {
    background-color: #f9f9f9;
    font-weight: bold;
}

.news-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.news-table tr:hover {
    background-color: #e2e2e2;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 65px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 8px 16px;
    border: none;
    background-color: #ddd;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #f00;
    color: white;
  }
  
  .pagination button:hover {
    background-color: #ccc;
  }
  
  .pagination button[disabled] {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
  