.missions-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 60px 20px;
  border-radius: 20px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  flex-direction: row;
}

.missions-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  max-width: 600px;
  margin-right: 20px;
  padding-bottom: 30px;
}

.missions-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  border: 3px solid #ffffff;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.missions-image:hover {
  transform: scale(1.05);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.5);
}

.missions-image:active {
  transform: scale(0.98);
}

.missions-text {
  width: 55%;
  color: #333;
  padding: 20px;
  text-align: justify; /* Spread text evenly across lines */
}

.missions-text h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 25px;
  color: #2c3e50;
}

.missions-text h2 {
  font-size: 24px;
  margin-top: 40px;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
}

.missions-text p {
  font-size: 22px;
  line-height: 1.8;
  margin: 0;
  color: #555;
  text-align: justify; /* Justify text */
  word-break: break-word;
}

.document-context button {
  padding: 8px 16px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
}

.accordion {
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Ensures the columns are spaced evenly */
}

.accordion-item {
  width: 48%; /* Slightly less than half to accommodate margin */
  margin-bottom: 20px; /* Space between items */
}

.accordion-item button {
  padding: 8px 16px;
  cursor: pointer;
  background-color: pink;
  color: black;
  border: none;
  border-radius: 4px;
  width: 100%; /* Full width */
  text-align: left; /* Align text to the left */
  font-size: 1em;
}

.accordion-item button:hover {
  background-color: lightgray; /* Darker blue on hover */
}


.content {
  padding: 0 15px;
  background: #f0f0f0; /* Light grey background */
  border: 1px solid #ccc; /* Light grey border */
  margin: 5px 0;
  font-size: 18px;
}

/* Adjustments for tablet screens (like iPad) */
@media (max-width: 1024px) {
  .missions-container {
    padding: 40px 15px;
  }

  .missions-text p {
    font-size: 20px;
    line-height: 1.8;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .missions-container {
    flex-direction: column;
    padding: 20px 10px;
  }

  .missions-image-container {
    margin-bottom: 15px;
    width: 100%;
  }

  .missions-text {
    width: 100%;
    padding: 0 15px;
    text-align: justify;
    line-height: 2.0;
    word-break: break-word;
  }

  .missions-text h1 {
    font-size: 30px;
  }

  .missions-text h2 {
    font-size: 20px;
  }

  .missions-text p {
    font-size: 22px !important;
    line-height: 2.0;
    margin: 0;
    word-break: break-word;
    padding: 0;
  }

  .accordion {
    flex-direction: column;
  }

  .accordion-item {
    width: 100%;
  }
}



/* Further small screen adjustments */
@media (max-width: 480px) {
  .missions-text h1 {
    font-size: 20px;
  }

  .missions-text p {
    font-size: 16px;
    line-height: 1.6;
    padding: 0 15px;
  }

}
