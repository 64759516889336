.album-title {
  text-align: center;
  margin-left: 20px;
  margin-bottom: 20px; /* Add some space under the title */
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns per row */
  gap: 20px; /* Space between the photos */
}

.photo-item {
  display: flex;
  flex-direction: column; /* Ensure the image and text are stacked */
  justify-content: space-between; /* Space out image and text */
  height: 100%; /* Keep full height for grid items */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-align: center;
}

.photo-item img {
  width: 100%;
  height: 300px; /* Keep the fixed height */
  object-fit: cover; /* Maintain aspect ratio while covering the container */
  border-bottom: 1px solid #ddd;
}

.photo-item h3 {
  font-size: 18px;
  margin: 10px 0;
  color: #333;
}

.photo-item p {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}
.photo-album {
  margin-bottom: 0; /* Remove excess bottom margin */
  padding-bottom: 0; /* Remove padding at the bottom */
}

.photo-album-title {
  margin-bottom: -2px;
}

/* For tablet screens */
@media (max-width: 1024px) {
  .photo-grid {
    grid-template-columns: repeat(2, 1fr); /* Change to 2 columns */
  }
}

/* For mobile screens */
@media (max-width: 768px) {
  .photo-grid {
    grid-template-columns: 1fr; /* Single column layout */
  }

  .photo-item {
    margin-bottom: 20px;
  }

  .photo-item img {
    object-fit: contain; /* Adjust to fit properly on mobile */
    height: auto; /* Ensure the full image is displayed */
  }
}
