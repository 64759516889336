.swiper {
  width: 100%;
  height: 80vh;
  margin-bottom: 40px;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover; /* Change to cover for smoother scaling */
  background-position: center;
  height: 100%;
  color: white;
}

.slide-content {
  text-align: center;
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
  width: fit-content;
  margin: 0 auto;
}

.slide-content h2 {
  font-size: 48px;
  margin-bottom: 5px; /* Reduce the space between the header and the paragraph */
  line-height: 1.1; /* Adjust the line height to reduce spacing */
  color: #ffffff;
}

.slide-content p {
  font-size: 28px;
  line-height: 1.1; /* Adjust the line height */
  margin-top: 0; /* Remove the margin at the top of the paragraph */
  color: #ffffff;
}

.slide-bible {
  justify-content: flex-start; /* Move content to the left for Bible slide */
}

.slide-content-right {
  margin-left: 60%; /* Keep content to the right of the Bible */
  text-align: left; /* Align text to the left */
  background-color: transparent; /* Transparent background */
  padding: 5px;
  width: fit-content;
  font-size: 28px;
}

.slide-content-right h2 {
  font-size: 48px;
  margin-bottom: 2px; /* Reduce space between h2 and paragraph */
  line-height: 1; /* Reduce line spacing */
  color: #ffffff;
  text-align: center; /* Center the heading text */
}

.slide-content-right p {
  font-size: 28px;
  margin-top: 0; /* Remove top margin */
  line-height: 1; /* Reduce line spacing */
  color: #ffffff;
  text-align: center; /* Center the paragraph text */
}


@media (min-width: 768px) and (max-width: 1024px) {
  /* For tablet screen sizes like iPad */
  .slide-content h2 {
    font-size: 36px; /* Slightly reduce font size for tablets */
    line-height: 1.2; /* Keep line height manageable */
  }

  .slide-content p {
    font-size: 24px; /* Reduce paragraph font size */
    line-height: 1.2;
  }
  .slide-content-right h2 {
    font-size: 35px;
  }
  .slide-content-right p {
    font-size: 22px;
  }
} 


@media (min-width: 1025px) {
  /* For larger screens */
  .slide-content h2 {
    font-size: 48px; /* Larger text for bigger screens */
    line-height: 1.1; /* Reduce line height to avoid choppiness */
  }

  .slide-content p {
    font-size: 28px; /* Adjust paragraph text size */
    line-height: 1.2;
  }
}






/* Media query for smaller screens (mobile) */
@media (max-width: 768px) {
  .swiper {
    width: 100%; /* Take full width of the screen */
    height: 60vh; /* Reduce height on smaller screens */
  }

  .slide {
    background-size: cover; /* Ensure the image covers the slide properly */
  }

  .slide-content {
    padding: 10px; /* Reduce padding */
  }

  .slide-content h2 {
    font-size: 18px; /* Reduce heading font size */
  }

  .slide-content p {
    font-size: 16px; /* Reduce paragraph font size */
  }

  .slide-bible {
    justify-content: center; /* Center the Bible slide content */
  }

  .slide-content-right {
    margin-left: 45%; /* Remove left margin to center content */
    padding: 10px; /* Adjust padding */
    text-align: left; /* Align text to the left */
  }

  .slide-content-right h2 {
    font-size: 18px; /* Reduce heading font size */
    margin-left: -30px;
  }

  .slide-content-right p {
    font-size: 13px; /* Reduce paragraph font size */
    margin-left: -30px;

  }
}
