/* General header styles for larger screens */
.header-container {
  width: 100%;
  background-color: white;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  align-items: center; /* Ensures the logo and text are aligned vertically */
  justify-content: space-between;
  padding: 0 20px;
}

.header-left {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
}

.church-logo {
  max-height: 100px; /* Adjust logo size */
  margin-right: 0px; /* Adds space between the logo and the text */
}

.church-name {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Ensures the text aligns to the center of the logo */
}

.church-name h1 {
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  color: #333;
}

.church-name h2 {
  margin: 0;
  font-size: 15px;
  color: #666;
  margin-top: 5px;
}


/* Desktop Navigation */
.header-nav.desktop {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center; /* Ensure vertical alignment */
}

.header-nav.desktop ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; /* Display links in a row */
}

.header-nav.desktop ul li {
  margin: 0 20px;
}

.header-nav.desktop ul li a {
  color: #333;
  font-size: 20px;
  text-decoration: none;
}

.header-nav.desktop ul li a.highlighted {
  color: #1e90ff;
}

.header-nav.desktop ul li a:hover {
  color: #1e90ff; /* Change the color to blue when hovered */
  transition: color 0.3s ease-in-out; /* Smooth color transition */
}

/* General hamburger styles */
.hamburger {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  width: 30px; /* Set width */
  height: 24px; /* Set height */
  margin-left: 20px; /* Align next to text with space */
  z-index: 1001;
  transition: all 0.3s ease-in-out;
}

/* Align the hamburger icon horizontally next to the text */
.header-left {
  display: flex;
  align-items: center; /* Ensures logo, text, and hamburger are aligned */
}

/* Hamburger bars */
.hamburger .bar {
  width: 100%;
  height: 3px;
  background-color: #333;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

/* Animate hamburger when opened */
.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
  background-color: #1e90ff; /* Change color */
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0; /* Hide middle bar */
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
  background-color: #1e90ff; /* Change color */
}


/* Mobile Navigation */
.header-nav.mobile {
  display: none; /* Initially hidden */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  width: 100%;
  top: 100px;
  left: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  z-index: 999;
}

.header-nav.mobile.open {
  display: flex;
  height: auto; /* Show when open */
}

.header-nav.mobile ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header-nav.mobile ul li {
  margin: 10px 0;
}

.header-nav.mobile ul li a {
  color: #333;
  font-size: 18px;
  text-decoration: none;
}

.header-nav.mobile ul li a:hover {
  color: #1e90ff; /* Same hover color for mobile */
  transition: color 0.3s ease-in-out;
}

/* Responsive settings */
@media (max-width: 768px) {
  .header-nav.desktop {
    display: none; /* Hide desktop navigation on mobile */
  }

  .hamburger {
    display: flex; /* Show burger menu on mobile */
  }

  .header-nav.mobile {
    display: none; /* Initially hide the mobile nav */
  }

  .header-nav.mobile.open {
    display: flex; /* Show the mobile nav on open */
    transition: height 0.3s ease-in-out; /* Smooth transition */
  }

  .header-content {
    padding-left: 0px; /* Add a bit of space from the left edge */
  }

  .church-logo {
    margin-right: 10px; /* Space between logo and text */
    max-height: 75px; /* Resize the logo for mobile */
  }
  
  .church-name h1 {
    font-size: 20px; /* Adjust the size for mobile */
    margin-top: -11px; /* Move the text up */
    margin-left: -25px;
  }

  .church-name h2 {
    font-size: 12px; /* Optional: Adjust the English text size on mobile as well */
    margin-top: -1px; /* Move the English text up a little */
    margin-left: -25px;

  }
  .header-left {
    display: flex;
    align-items: center; /* Ensure logo and text are aligned side by side */
  }
}

@media (min-width: 769px) {
  .hamburger {
    display: none; /* Hide hamburger on larger screens */
  }

  .header-nav.desktop {
    display: flex; /* Show desktop navigation on larger screens */
  }
}


/* Tablet adjustments (iPad Air, etc.) */
@media (min-width: 820px) and (max-width: 1024px) {
  .church-name h1 {
    font-size: 20px; /* Adjusted for smoother appearance on tablets */
  }

  .church-name h2 {
    font-size: 16px; /* Adjust English text size */
  }

  .header-nav.desktop ul li a {
    font-size: 13px; /* Reduced font size to fit better */
    margin: 0 8px; /* Adjusted spacing between items */
    white-space: nowrap; /* Prevent items from wrapping to a new line */
}

  .church-logo {
      max-height: 80px; /* Further reduced logo size for better fit */
      margin-right: 10px; /* Slightly reduced space between logo and text */
  }

  .header-content {
      padding: 0 20px; /* Reduced padding to allow more space for navigation */
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  /* Ensure proper alignment for tablet devices */
  .header-nav.desktop ul {
    justify-content: center; /* Center align navigation items */
  }

  .header-nav.desktop ul li {
    margin-right: 15px; /* Reduce margin between items */
  }

  .header-content {
    justify-content: space-between;
    align-items: center; /* Center content vertically */
  }

}
