/* Footer.css */

.Footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 17px;
  width: 100%;
  position: absolute; /* Ensure the footer is positioned at the bottom */
  bottom: 0;
  left: 0;
  font-size: 12px;
}

/* Ensure the html and body take the full height of the viewport */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrollbars */
}

/* Ensure the root element and main content fill the height */
#root {
  min-height: 100vh; /* Set minimum height to fill the viewport */
  position: relative;
}

/* Main content takes up remaining space */
main {
  padding-bottom: 50px; /* Ensure the content doesn't overlap with the footer */
  box-sizing: border-box;
  min-height: 100%;
}
