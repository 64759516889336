.contact-page {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4; /* Lighter background */
  min-height: 100vh; /* Ensure it takes full height */
}

.contact-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;  
  max-width: 1200px;  
  gap: 30px;  /* Slightly increased gap for better spacing */
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info {
  flex: 1;
  margin-right: 20px;
  max-width: 500px;  
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure spacing within the form */
}

.contact-info h2 {
  font-size: 32px;
  color: #2c3e50;
  margin-bottom: 20px;
  font-weight: 600;
}

.contact-info p {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

.map-container {
  flex: 1;
  max-width: 600px;  
  height: 600px;
}

.contact-form {
  background-color: #fafafa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: auto; /* Adjusted to fit the content */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure space inside form */
}

.contact-form h3 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}


/* Full width for mobile screens */
@media (max-width: 768px) {
  .contact-wrapper {
    display: block; /* Stack elements naturally */
    width: 100vw; /* Full width of the viewport */
    margin: 0; /* Remove any margins */
    padding: 0; /* Remove padding */
    box-sizing: border-box; /* Include padding/margins within the width */
    background-color: white; /* Ensure the white background spans fully */
  }

  .contact-info {
    width: 100%; /* Make the contact info span the full width */
    padding: 20px; /* Add padding for spacing */
    text-align: center; /* Center-align the text */
    font-size: 16px; /* Adjust font size for readability */
    line-height: 1.5; /* Improve text readability */
    box-sizing: border-box; /* Ensure padding doesn’t cause overflow */
  }

  .map-container {
    width: 100%; /* Full width for the map container */
    margin: 0; /* Remove any extra margins */
    padding: 0; /* Remove padding for seamless alignment */
    margin-bottom: 20px; /* Add space below the map */
  }

  .map-container iframe {
    width: 100%; /* Full width for the iframe */
    height: 150px; /* Shrink the height of the map */
    border: none; /* Remove border for a clean look */
  }

  .footer {
    margin-top: 100px; /* Add space above the footer to prevent overlap */
  }
}


/* Adjustments for tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .contact-wrapper {
    flex-direction: column; /* Stack content vertically for tablets */
    padding: 20px; /* Consistent padding */
    gap: 20px; /* Space between sections */
  }

  .contact-info {
    width: 100%; /* Full width for tablet */
    padding: 20px;
    text-align: center; /* Center the text */
  }

  .map-container {
    width: 100%; /* Full width for tablet */
    height: 500px; /* Adjust height for better visibility */

  }

  .map-container iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
