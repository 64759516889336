/* Reset margins and padding for the entire page */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  /* Set full-width and height for the youth page */
  .youth-page {
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  /* Ensure the image container spans the entire width of the viewport */
  .header-image-container {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    margin: 0;
  }
  
  /* Ensure the image fills the entire container with no gaps */
  .header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  /* Text styling over the image */
  .header-text {
    position: absolute;
    bottom: 50px;
    left: 50px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  .header-text h1 {
    font-size: 36px;
    font-weight: bold;
  }
  
  .header-text p {
    font-size: 35px;
  }
  
  /* Info Section Above the Images */
.info-section {
    margin: 20px auto;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  
  .info-section h2 {
    margin: 10px 0;
  }
  
  /* Styling for additional youth group images */
  /* Adding white space below the images */
.youth-images {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 70px; /* Adds white space below the images */
  }
  
  
  .youth-image {
    width: 45%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .youth-images {
      flex-direction: column;
      align-items: center;
    }
  
    .youth-image {
      width: 90%;
      margin-bottom: 20px;
    }
  }
  