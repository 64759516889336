/* General Layout */
.church-photo-gallery-container {
  text-align: center;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 50px;
}

/* Gallery Grid with larger images and reduced gap */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two items per row */
  gap: 10px; /* Reduced gap between items */
  justify-items: center;
  margin-bottom: 70px;
}

.gallery-item {
  cursor: pointer;
  width: 80%;
  max-width: 600px; /* Increased max width for larger images */
  transition: transform 0.2s ease;
}

.gallery-item:hover {
  transform: scale(1.05);
}

/* Standardized size for both images and videos */
.gallery-item img,
.gallery-item video {
  width: 500px;
  height: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  object-fit: cover;
}

.gallery-item p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}


/* Adjust the gallery for small screens (mobile devices) */
@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: 1fr; /* One item per row */
    gap: 15px; /* Increase gap if needed */
    margin-bottom: 70px;
  }

  .gallery-item {
    max-width: 100%; /* Ensure images cover full width */
    width: 100%;
  }
}
