/* App.css */

/* General App container */
.App {
  text-align: center;
  padding: 10px;
}

/* Adjusting the logo for smaller screens */
.App-logo {
  height: 30vmin; /* Reduced the height for smaller screens */
  pointer-events: none;
}

/* Header Adjustments */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin); /* Adjust font-size for mobile */
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Contact Section Styling */
.contact-section {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;
}

.contact-form {
  width: 300px;
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.contact-form button {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #45a049;
}

/* Adjust Google Map size */
.google-map {
  width: 300px;
  height: 300px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Responsive Design */
@media (max-width: 600px) {
  .App-header {
    font-size: calc(10px + 1.5vmin); /* Smaller font for mobile */
  }

  .App-logo {
    height: 20vmin; /* Reduce the size of the logo on mobile */
  }

  .contact-section {
    flex-direction: column; /* Stack contact form and map vertically */
    align-items: center;
  }

  .contact-form {
    width: 90%; /* Make form full width on mobile */
  }

  .google-map {
    width: 100%; /* Full width map on mobile */
  }

  /* Button Adjustments */
  .contact-form button {
    padding: 8px; /* Make the button slightly smaller */
    font-size: 14px;
  }
}
