.youtube-page {
  text-align: center;
  padding: 20px;
  margin-bottom: 40px; 
}

.youtube-logo {
  width: 200px; /* You can adjust this value to make the logo smaller */
  margin-right: 10px;
  vertical-align: middle;
}

.video-grid {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
}

.video-item {
  width: 400px;
  text-align: center;
}

.video-item p {
  font-size: 18px;
  margin-top: 10px;
}

.eng-text {
  font-style: italic;
  color: #888;
}

/* Mobile and smaller screens */
@media (max-width: 768px) {
  .youtube-logo {
    width: 150px; /* Smaller logo for mobile */
  }

  .video-grid {
    flex-direction: column; /* Stack videos vertically on mobile */
    align-items: center; /* Center align the items */
  }

  .video-item {
    width: 90%; /* Full width on mobile screens */
  }

  .video-item p {
    font-size: 16px; /* Slightly smaller font size on mobile */
  }
}

/* Tablet screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .video-item {
    width: 45%; /* Adjust width for tablet */
  }

  .video-item p {
    font-size: 17px; /* Adjust font size for better readability */
  }
}

