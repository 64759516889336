.about-page {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  padding: 0px;
}

/* Styling for the header section with the image */
.header-image-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin-bottom: 40px;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-text {
  position: absolute;
  bottom: 30px;
  left: 50px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Shadow for better readability */
}

.header-text h1 {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
}

.header-text p {
  font-size: 18px;
  margin: 5px 0 0;
}

/* Main container to place details and video side by side */
.content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 40px auto;
  max-width: 1200px;
  gap: 50px; /* Increased spacing for neatness */
}

/* Styling for the worship details section */
.worship-details {
  flex: 1;
  text-align: left;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.worship-details h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  border-bottom: 2px solid #1f2e4d;
  padding-bottom: 10px;
}

.worship-details ul {
  list-style: none;
  padding-left: 0;
  font-size: 20px;
  color: #555;
}

.worship-details ul li {
  margin-bottom: 12px;
  line-height: 1.6;
}

.worship-details ul li strong {
  color: #1f2e4d;
  font-size: 22px;
}

.worship-details ul li .time {
  color: #ff4081;
  font-weight: bold;
}


.video-section {
  flex: 1;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.video-section iframe {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: 10px;
}

/* Styling for the sermon date */
.sermon-date {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #555;
}

/* Responsive styling */
@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .worship-details {
    text-align: center;
    margin-bottom: 30px;
  }

  .worship-details ul {
    font-size: 18px;
  }
  
  .worship-details ul li .time {
    color: #ff4081;
    font-weight: bold;
    white-space: nowrap; /* Ensure the time stays on a single line */
  }

  .video-section {
    margin-bottom: 20px;
  }

  .video-section iframe {
    height: 300px;
    
  }

  @media (max-width: 480px) { 

    .video-section {
      margin-bottom: 40px;
      
    }

  }


}
